import React, { useContext } from 'react';
import Moment from 'react-moment';
import dompurify from 'dompurify';

// Hooks

// Context
import { FormDataContext } from 'globalState/FormDataContext';
// Components
import Table from 'components/shared/Table/Table';
import GetMap from 'components/shared/Map/Map';

import ConsentForm from '../StepConsentForm';

const { sanitize } = dompurify;

function StepSummarySection() {
  const [formDataState, formDataDispatch] = useContext(FormDataContext);
  const {
    Title,
    Firstname,
    LastName,
    PublicGeneralEnquiry,
    Email,
    PhoneNumber,
    DateOfIncident,
    DateOfIncidentFrom,
    TimeOfIncident,
    TimeOfIncidentFrom,
    TimeOfIncidentFromAmPm,
    TimeOfIncidentTo,
    TimeOfIncidentToAmPm,
    TransportMode,
    BusServiceNumber,
    TravelDirection,
    Town,
    RoadName,
    IncidentType,
    IncidentDetails,
    OffenderImages,
    PhotoName,
    TrainRoute,
    RailStation,
    TramDirection,
    TramStop,
    PhotoBase64,
    address,
    postcode,
    LocationDetails,
    ViewMap,
  } = formDataState.formData;

  // if (formDataState.formData.TravelDirection !== '') {
  //   formDataState.formData.postcode = '';
  // }

  // convert time to 24hr time
  const timeFrom = `${formDataState.formData.TimeOfIncidentFrom} ${formDataState.formData.TimeOfIncidentFromAmPm}`;
  const timeTo = `${formDataState.formData.TimeOfIncidentTo} ${formDataState.formData.TimeOfIncidentToAmPm}`;

  function convertTo24HourFormat(timeString) {
    const [time, period] = timeString.split(' ');
    const [hour, minute] = time.split(':');
    let formattedHour = parseInt(hour, 10);

    if (period === 'PM') {
      formattedHour += 12;
    }

    // add another 0 if hour is less than 10
    function hoursWithZeros(dt) {
      if (dt > '10') {
        return `0${dt}`;
      }
      return dt;
    }

    // add another 0 if minute is 0
    function minutesWithLeadingZeros(dt) {
      if (dt === '0') {
        return '00';
      }
      return dt;
    }

    const min = minutesWithLeadingZeros(minute);
    const hr = hoursWithZeros(hour);

    return `${hr}:${min}`;
  }

  const formattedFromTime = convertTo24HourFormat(timeFrom);
  const formattedToTime = convertTo24HourFormat(timeTo);

  // get date am/pm
  const fromAmPm = TimeOfIncidentFromAmPm.toLowerCase();
  const toAmPm = TimeOfIncidentToAmPm.toLowerCase();

  const setStepInContext = (st) => {
    formDataDispatch({
      type: 'UPDATE_STEP',
      payload: st,
    });
  };

  const getCoords = (value) => {
    if (value) {
      const coords = value.split('place/')[1].split(',');
      return coords;
    }
    return []; // Or handle the case where value is undefined in your preferred way
  };

  const title = 'Check your answers';

  /* Table Data */

  // report
  const dataLine1 = [];
  if (PublicGeneralEnquiry) {
    dataLine1.push(<span>{PublicGeneralEnquiry}</span>);
    dataLine1.push(
      <button
        type="button"
        className="ds-btn ds-btn--link ds-float-right"
        onClick={() => {
          setStepInContext(3);
        }}
      >
        Change
      </button>
    );
  }

  // name
  const dataLine2 = [];
  dataLine2.push(<span>Name</span>);
  dataLine2.push(<span>{`${Title} ${Firstname} ${LastName}`}</span>);
  dataLine2.push(
    <button
      type="button"
      className="ds-btn ds-btn--link"
      onClick={() => {
        setStepInContext(2);
      }}
    >
      Change
    </button>
  );

  // email
  const dataLine3 = [];
  dataLine3.push(<span>Email</span>);
  dataLine3.push(<span>{`${Email} `}</span>);
  dataLine3.push(
    <button
      type="button"
      className="ds-btn ds-btn--link"
      onClick={() => {
        setStepInContext(3);
      }}
    >
      Change
    </button>
  );

  // phone number
  const dataLine4 = [];
  dataLine4.push(<span>Phone number</span>);
  dataLine4.push(<span>{`${PhoneNumber} `}</span>);
  dataLine4.push(
    <button
      type="button"
      className="ds-btn ds-btn--link"
      onClick={() => {
        setStepInContext(4);
      }}
    >
      Change
    </button>
  );

  // date and time of the incident
  const dataLine5 = [];
  dataLine5.push(<span>When did the incident happen?</span>);
  dataLine5.push(
    <span>{`${DateOfIncident} at ${formattedFromTime}${fromAmPm} to  ${formattedToTime}${toAmPm}`}</span>
  );
  dataLine5.push(
    <button
      type="button"
      className="ds-btn ds-btn--link"
      onClick={() => {
        setStepInContext(5);
      }}
    >
      Change
    </button>
  );

  // incident mode of transport
  const dataLine6 = [];
  dataLine6.push(<span>What mode of transport?</span>);
  dataLine6.push(<span>{`${TransportMode}`}</span>);
  dataLine6.push(
    <button
      type="button"
      className="ds-btn ds-btn--link"
      onClick={() => {
        setStepInContext(6);
      }}
    >
      Change
    </button>
  );

  // incident location
  const dataLine7 = [];

  if (TransportMode.toLowerCase() === 'bus') {
    dataLine7.push(<span>Where did the incident happen?</span>);
    // if viewMap was selected
    if (ViewMap === 'true') {
      dataLine7.push(
        <span>
          {postcode !== null && typeof postcode !== 'undefined' && (
            <>
              <GetMap lat={getCoords(LocationDetails)[1]} lang={getCoords(LocationDetails)[0]} />
            </>
          )}
        </span>
      );
    } else {
      dataLine7.push(
        <span>{`${BusServiceNumber}, ${TravelDirection}, ${Town}, ${RoadName}`}</span>
      );
    }
  } else if (TransportMode === 'Train') {
    dataLine7.push(<span>Train Route</span>);
    dataLine7.push(<span>{`${TrainRoute}`}</span>);
  } else if (TransportMode === 'Tram') {
    dataLine7.push(<span>Tram Direction</span>);
    dataLine7.push(<span>{`${TramDirection}`}</span>);
  }
  dataLine7.push(
    <button
      type="button"
      className="ds-btn ds-btn--link"
      onClick={() => {
        setStepInContext(7);
      }}
    >
      Change
    </button>
  );

  // incident location
  const dataLine8 = [];
  if (TransportMode.toLowerCase() === 'train') {
    dataLine8.push(<span>Nearest Station</span>);
    dataLine8.push(<span>{`${RailStation}`}</span>);
    dataLine8.push(
      <button
        type="button"
        className="ds-btn ds-btn--link"
        onClick={() => {
          setStepInContext(7);
        }}
      >
        Change
      </button>
    );
  }
  const dataLine9 = [];
  if (TransportMode.toLowerCase() === 'tram') {
    dataLine9.push(<span>Nearest Station</span>);
    dataLine9.push(<span>{`${TramStop}`}</span>);
    dataLine9.push(
      <button
        type="button"
        className="ds-btn ds-btn--link"
        onClick={() => {
          setStepInContext(7);
        }}
      >
        Change
      </button>
    );
  }

  // date and time of the incident
  const dataLine10 = [];
  dataLine10.push(<span>Type of incident</span>);
  dataLine10.push(<span>{`${IncidentType}`}</span>);
  dataLine10.push(
    <button
      type="button"
      className="ds-btn ds-btn--link"
      onClick={() => {
        setStepInContext(8);
      }}
    >
      Change
    </button>
  );

  // incident details
  const dataLine11 = [];
  dataLine11.push(<span>Incident description</span>);
  dataLine11.push(<span>{`${IncidentDetails}`}</span>);
  dataLine11.push(
    <button
      type="button"
      className="ds-btn ds-btn--link"
      onClick={() => {
        setStepInContext(9);
      }}
    >
      Change
    </button>
  );
  // have offender's photo
  const dataLine12 = [];
  dataLine12.push(<span>Do you have a photo of the offender?</span>);
  let yesno = 'No';
  if (OffenderImages && OffenderImages.toLowerCase() === 'yes') {
    yesno = 'Yes';
  }
  dataLine12.push(<span>{`${yesno}`}</span>);
  dataLine12.push(
    <button
      type="button"
      className="ds-btn ds-btn--link"
      onClick={() => {
        setStepInContext(10);
      }}
    >
      Change
    </button>
  );

  // photo of the offender
  // const dataLine13 = [];
  // if (OffenderImages && OffenderImages.toLowerCase() === 'yes') {
  //   dataLine13.push(<span>Photo of the offender</span>);
  //   dataLine13.push(
  //     <span key="image-span">
  //       {PhotoName}
  //       <br />
  //       <img src={`data:image/png;base64,${PhotoBase64}`} alt={PhotoBase64} key="image" />
  //     </span>
  //   );
  //   dataLine13.push(
  //     <button
  //       type="button"
  //       className="ds-btn ds-btn--link"
  //       onClick={() => {
  //         setStepInContext(11);
  //       }}
  //     >
  //       Change
  //     </button>
  //   );
  // }

  /* End of Table Data */

  const data = [dataLine2, dataLine3, dataLine4];
  const incident = [
    dataLine5,
    dataLine6,
    dataLine7,
    dataLine8,
    dataLine9,
    dataLine10,
    dataLine11,
    dataLine12,
    // dataLine13,
  ];

  return (
    <>
      <div className="ds-col-1">
        <h2 className="ds-fe-question ds-m-t-none">{title}</h2>
        <p>postcode: {postcode}</p>
        <Table
          title="About you"
          classes=""
          cellClasses={['', '', 'ds-text-align-right ds-p-r-none']}
          headers={[]}
          values={data}
          data-private
        />
        <Table
          title="About the incident"
          classes=""
          cellClasses={['', '', 'ds-text-align-right ds-p-r-none']}
          headers={[]}
          values={incident}
          data-private
        />
        <h3>Now send your report</h3>
        <p>
          By submitting this report you are confirming that, to the best of your knowledge, the
          details you are providing are correct.
        </p>
        <ConsentForm />
      </div>
    </>
  );
}

export default StepSummarySection;
