/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useContext } from 'react';
// Context
import { FormDataContext } from 'globalState/FormDataContext';
// Import custom hooks
import useStepLogic from 'components/Form/useStepLogic';
// Import components
import Radios from 'components/shared/FormElements/Radios/Radios';
import SectionStepInfo from 'components/shared/SectionStepInfo/SectionStepInfo';

const EnquirySelection = () => {
  const formRef = useRef(); // Used so we can keep track of the form DOM element
  const { register, handleSubmit, showGenericError, continueButton } = useStepLogic(formRef); // Custom hook for handling continue button (validation, errors etc)

  const radioButtons = [
    { text: 'I am a Member of the public', value: 'Member of the public' },
    {
      text: 'I am a member of staff for Transport for West Midlands (TfWM)',
      value: 'TfWM',
    },
    {
      text: 'I am a member of staff for a transport operator',
      value: 'Transport operator',
    },
  ];

  const [formDataState] = useContext(FormDataContext);
  const { ReportedByType } = formDataState.formData;
  const { PublicIncidentReport } = formDataState.formData;
  const { StaffEnquiry } = formDataState.formData;

  // Make public report null so it hides event info on other paths
  if (ReportedByType !== 'Member of the public' && PublicIncidentReport != null) {
    formDataState.formData.PublicIncidentReport = null;
  }

  // Make staff report null so it hides on other paths
  if (ReportedByType !== 'staff' && StaffEnquiry != null) {
    formDataState.formData.StaffEnquiry = null;
  }

  // reset the map session storage
  if (sessionStorage.getItem('longitude') != null) {
    sessionStorage.setItem('longitude', '');
  }

  if (sessionStorage.getItem('latitude') != null) {
    sessionStorage.setItem('latitude', '');
  }

  const title = 'Which best describes you?';
  return (
    <form onSubmit={handleSubmit} ref={formRef} autoComplete="on">
      <SectionStepInfo section="Section 1" description="About you" />
      {/* Show generic error message */}
      {showGenericError}

      <fieldset className="ds-fe-fieldset ds-col-1">
        <legend className="ds-fe-fieldset__legend">
          <h2 className="ds-fe-question">{title}</h2>
        </legend>

        <Radios
          name="ReportedByType"
          radios={radioButtons}
          fieldValidation={register({
            required: `Please select one option to proceed`,
          })}
        />
      </fieldset>

      {/* Continue button */}
      {continueButton()}
    </form>
  );
};

export default EnquirySelection;
